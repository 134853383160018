export default [
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/register/Register'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      protected: false,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      protected: false,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/pages/authentication/Logout.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      protected: false,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/authentication/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      protected: false,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/pages/terms/Terms'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      protected: false,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      protected: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      protected: false,
    },
  },
  {
    path: '/claim',
    name: 'claim',
    component: () => import('@/views/pages/authentication/Claim.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      protected: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      protected: false,
    },
  },
  {
    path: '/claim/success',
    name: 'claim-success',
    component: () => import('@/views/pages/authentication/ClaimSuccess.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      protected: false,
    },
  },
]
