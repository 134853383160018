export default [
    {
        path: '/my-profile',
        name: 'my-profile',
        component: () => import('@/views/pages/my-profile/MyProfile')
    },
    {
        path: '/my-profile/edit',
        name: 'edit-my-profile',
        component: () => import('@/views/pages/my-profile/EditProfile')
    },
    {
        path: '/my-profile/edit/change-password',
        name: 'change-password',
        component: () => import('@/views/pages/my-profile/ChangePassword')
    },
    {
        path: '/my-profile/edit/change-email',
        name: 'change-email',
        component: () => import('@/views/pages/my-profile/ChangeEmail')
    },
    {
        path: '/manage-subscription',
        name: 'manage-subscription',
        component: () => import('@/views/pages/my-profile/ManageSubscription')
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: () => import('@/views/pages/my-profile/Thanks')
    },
    {
        path: '/thanks-interest',
        name: 'thanks-interest',
        component: () => import('@/views/pages/my-profile/ThanksInterest')
    },
]
