<template>
  <div>
    <GmapMap :center="position" :zoom="zoom" :style="styling">
      <GmapMarker
        v-if="showMarker"
        :position="position"
        :clickable="true"
        :draggable="draggable"
        @drag="getPosition"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    location: Object,
    styling: String,
    zoom: {
      type: Number,
      default: 6,
    },
    showMarker: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      position: {},
      currentPlace: null,
    }
  },
  mounted() {
    if (this.location.lng == 0 && this.location.lat == 0) {
      navigator.geolocation.getCurrentPosition(position => {
        this.position.lng = +position.coords.longitude
        this.position.lat = +position.coords.latitude
      })
    } else {
      this.setLocation()
    }
  },
  methods: {
    getPosition(position) {
      this.$emit('input', {
        lng: position.latLng.lng(),
        lat: position.latLng.lat(),
      })
    },
    setLocation() {
      this.position.lng = +this.location.lng
      this.position.lat = +this.location.lat
    },
  },
}
</script>

<style scoped></style>
