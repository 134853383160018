import CrudService from '@/services/shared/http/CrudService'

export default class MyProfileService extends CrudService {
  constructor(params = {}) {
    super('my-profile', params)
  }

  getProfileDetails() {
    return this.api.GET(this.endPoint)
  }

  updateProfileDetails(formData) {
    return this.api.POST(this.endPoint, formData)
  }

  changePassword(formData) {
    return this.api.POST('change-password', formData)
  }

  changeEmail(formData) {
    return this.api.POST('change-email', formData)
  }

  activatePlan(formData) {
    return this.api.POST('post-pro-plan', formData)
  }

  getSubscription() {
    return this.api.GET('subscription')
  }
}
