import posthog from 'posthog-js'

export default {
  install(Vue) {
    Vue.prototype.$posthog = posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: 'https://hedgehog-service.toddl.co',
      ui_host: 'https://us.posthog.com',
    })
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      posthog.opt_out_capturing()
    }
  },
}
