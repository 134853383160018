export default [
    {
        path: '/activities/1',
        name: 'activities',
        component: () => import('@/views/pages/activities/Activities')
    },
    {
        path: '/activities/2',
        name: 'camps',
        component: () => import('@/views/pages/activities/Activities')
    },
    {
        path: '/activities/3',
        name: 'events',
        component: () => import('@/views/pages/activities/Activities')
    },
    {
        path: '/activities/:id/create',
        name: 'create-activity',
        component: () => import('@/views/pages/activities/Create')
    },
    {
        path: '/activities/:id/edit/:itemId',
        name: 'edit-activity',
        component: () => import('@/views/pages/activities/Edit')
    },
]
