import ToastificationContent from '@/components/ToastificationContent'
import Vue from 'vue'

export default function useAlert() {
  const alertMessage = ({ message, status, status_code, errors }) => {
    let ERROR = null

    if (errors) {
      if (errors[0] && typeof errors[0] === 'string') {
        ERROR = errors[0]
      } else {
        ERROR = errors[0][0]
      }
    }

    Vue.prototype.$toast(
      {
        component: ToastificationContent,
        props: {
          title: status ? 'Success' : 'Error',
          icon: status ? '' : 'AlertTriangleIcon',
          variant: status ? 'success' : 'danger',
          text: message ? message : status_code ? ERROR : 'Something went wrong 😱',
        },
      },
      {
        position: 'top-right',
      }
    )
  }

  const SuccessAlertMessage = message => {
    Vue.prototype.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: '',
          variant: 'success',
          text: message,
        },
      },
      {
        position: 'top-right',
      }
    )
  }

  return {
    alertMessage,
    SuccessAlertMessage,
  }
}
