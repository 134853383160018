export default [
    {
        path: '/locations',
        name: 'locations',
        component: () => import('@/views/pages/locations/Locations')
    },
    {
        path: '/locations/create',
        name: 'create-location',
        component: () => import('@/views/pages/locations/Create')
    },
    {
        path: '/locations/edit/:itemId',
        name: 'edit-location',
        component: () => import('@/views/pages/locations/Edit')
    },
]
