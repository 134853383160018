/** API Prefix */
const apiPrefix = '/api/'
const apiUrl = `${process.env.VUE_APP_API_URL}${apiPrefix}`
const crmToken = process.env.VUE_CRM_TOKEN
/** Configuration object */
const config = {
  // End points.
  apiUrl: apiUrl,
  endpoints: {
    users: `${apiUrl}/users`,
    login: `${apiUrl}/login`,
  },
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  crmToken,
}

export default config
