import MyProfileService from '@/services/MyProfileService'

export default {
  namespaced: true,
  state: {
    user: null,
    accessToken: localStorage.getItem('accessToken'),
  },
  getters: {
    isLoggedIn(state) {
      return Boolean(state.accessToken)
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val
    },
    setAccessToken(state, val) {
      state.accessToken = val
      localStorage.setItem('accessToken', val)
    },
    login(state, val) {
      state.accessToken = val.accessToken
      state.user = val.user
      localStorage.setItem('accessToken', val.accessToken)
    },
    logout(state) {
      state.accessToken = null
      state.user = null
      localStorage.removeItem('accessToken')
    },
  },
  actions: {
    async fetchUserData({ commit }) {
      const myProfileService = new MyProfileService()

      const data = await myProfileService.getProfileDetails()

      const { activity, types, ...user } = data

      commit('setUser', user)
    },
  },
}
