export default [
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('@/views/pages/bookings/Bookings'),
  },
  {
    path: '/booking-widget',
    name: 'widget',
    component: () => import('@/views/pages/bookings/Widget'),
  },
  {
    path: '/bookings/edit/:itemId',
    name: 'bookings-edit',
    component: () => import('@/views/pages/bookings/Edit'),
  },
]
