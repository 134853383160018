import activities from "./activities"
import locations from './locations'
import profile from "./profile"
import messages from "./messages"
import contacts from "./contacts"
import bookings from "./bookings"
import payments from "./payments"

export default [
    {
        path: '/overview',
        name: 'overview',
        component: () => import('@/views/pages/home/Home')
    },
    ...activities,
    ...locations,
    ...profile,
    ...contacts,
    ...bookings,
    ...payments,
    ...messages
]
