import Vue from 'vue'
import VueRouter from 'vue-router'
import posthog from 'posthog-js'

// Routes
import providerRoutes from './routes/provider/providerRoutes'
import auth from './routes/auth/auth'
import store from '@/store'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'overview' } },
    {
      path: '/home',
      redirect: { name: 'activities' },
    },
    ...auth,
    ...providerRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const protectedRoute = to.meta?.protected ?? true
  const isLoggedIn = store.getters['auth/isLoggedIn']

  if (protectedRoute && !to.meta.redirectIfLoggedIn && to.name !== 'auth-login' && !isLoggedIn)
    return next({ name: 'auth-login' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) return next('/')

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  Vue.nextTick(() => {
    posthog.capture('$pageleave', {
      $current_url: from.fullPath,
    })
    posthog.capture('$pageview', {
      $current_url: to.fullPath,
    })
  })
})

export default router
