export default [
    {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import('@/views/pages/profile/Subscribe')
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import('@/views/pages/payments/Payments')
    }
]
