export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/pages/contacts/Contacts'),
  },
  {
    path: '/contacts/create',
    name: 'contacts-create',
    component: () => import('@/views/pages/contacts/Create'),
  },
  {
    path: '/contacts/edit/:itemId',
    name: 'contacts-edit',
    component: () => import('@/views/pages/contacts/Edit'),
  },
]
